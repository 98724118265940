<template style="margin-bottom: 160px; margin-top: 10px;">
  <div>
      <vx-card title="Tax Invoice" style="margin-bottom: 160px; margin-top: 10px;">
          <vs-tabs :color="colorx" v-model="tabs">
              <vs-tab @click="colorx = 'success'" label="SN Registration">
                  <div class="con-tab-ejemplo">
                      <sn_register/>
                  </div>
              </vs-tab>
              <vs-tab @click="colorx = 'warning'" label="SN Allocation">
                  <div class="con-tab-ejemplo">
                      <sn_allocation/>
                  </div>
              </vs-tab>
              <vs-tab @click="colorx = 'warning'" label="Tax Generation">
                  <div class="con-tab-ejemplo">
                      <sn_generate/>
                  </div>
              </vs-tab>
              <vs-tab @click="colorx = 'primary'" label="Export CSV">
                  <div class="con-tab-ejemplo">
                      <sn_export/>
                  </div>
              </vs-tab>
          </vs-tabs>
      </vx-card>
  </div>
</template>
<script>
import sn_register from "./sn_register.vue";
import sn_allocation from "./sn_allocation.vue";
import sn_generate from "./sn_generate.vue";
import sn_export from "./export.vue";

export default {
  components: {
    sn_register,sn_allocation,sn_generate,sn_export,
  },
  props: [
    "territory",
    "salesChannel",
    "customerCategory",
    "startDate",
    "endDate",
  ],
  data() {
    return {
      params: {
        search: "",
        length: 10,
        page: 1,
        order: "asc",
        sort: "",
      },
      colorx: "success",
        tabs: 0,
      responseData: {},
      isActive: 1,
      valueOption: [],
      // detailShow: "vx-col md:w-1/2 w-full mb-base",
      // detailHide: "core vx-col md:w-1/1 w-full mb-base",
      // detail: false,
      selectedData: {},
      dataId: 0,
    };
  },
  watch: {},
  mounted() {
    // this.reloadData(this.params);
  },
  methods: {
    // handleCreate() {
    //   this.$router.push("/setting/approval/form");
    // },
    // handleEdit(id) {
    //   this.$router.push(`/setting/approval/form/${id}`);
    // },
    // this.$vs.loading();
    // this.$vs.loading.close();
  },
};
</script>
 <style scoped>
.nonfixed {
  position: inherit;
  padding-left: 20px;
}
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transform-origin-y: all 1s ease;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-right: 1px solid #7367f0;
  /* min-height: 800px; */
  /* height: 100%; */
  -webkit-mask-position-y: fixed;
  /* padding: 5px; */
}
.vs-con-table.stripe .tr-values:nth-child(2n) {
  background: beige;
}
.colored {
  border: 1px solid #7367f0;
  position: fixed;
  left: 4%;
  top: 40%;
  max-width: 50%;
  text-align: justify;
  word-wrap: break-word;
  z-index: 999999999999;
  background: antiquewhite;
  background-color: antiquewhite;
}
.red {
  background: red;
  background-color: red;
}
.yellow {
  background: yellow;
  background-color: yellow;
}
</style>
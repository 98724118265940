<template>
  <div class="vx-row">
    <div class="vx-col md:w-1/1 w-full">
      <vx-card title="Form Setting Approval">
        <div class="vx-row mb-6 ml-4 mr-4" style="z-index: 99999 !important">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Tax Entity</span>
          </div>
          <div class="vx-col sm:w-2/5 w-full">
            <!-- <multiselect
              class="selectExample"
              v-model="create.event"
              :options="option.event"
              :multiple="false"
              :allow-empty="false"
              :group-select="false"
              :max-height="160"
              :limit="4"
              @input="onSelectEvent"
              track-by="ID"
              label="Reference"
              :disabled="true" 
            >
              <template slot="singleLabel" slot-scope="dt">
                <span class="option__desc">
                  <span class="option__title">
                    {{ dt.option.Reference }}
                  </span>
                </span>
              </template>

              <template slot="option" slot-scope="dt">
                <div class="option__desc">
                  <span class="option__title">
                    {{ dt.option.Reference }}
                  </span>
                </div>
              </template>
            </multiselect> -->
            <formInputs :components="this.formTaxEntity.inputs" /> 
          </div>
        </div>
        <div class="vx-row mb-6 ml-4 mr-4">
          <div class="vx-col sm:w-1/5 w-full">
            <span>NPWP</span>
          </div>
          <div class="vx-col sm:w-2/5 w-full">
            <vs-input
              class="w-full" 
              v-validate="'required'"
              name="npwp"
              v-model="data.npwp"
              @input="onInput"
              :disabled="true"
            />
          </div>
        </div>
        <div class="vx-row mb-6 ml-4 mr-4">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Address</span>
          </div>
          <div class="vx-col sm:w-2/5 w-full">
            <vs-input
              class="w-full" 
              v-validate="'required'"
              name="npwp"
              v-model="data.npwp"
              @input="onInput"
              :disabled="true"
            />
          </div>
        </div>
        <div class="vx-row mb-6 ml-4 mr-4">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Year</span>
          </div>
          <div class="vx-col sm:w-2/5 w-full">
            <formInputs :components="this.formTaxEntity.inputs" /> 
          </div>
        </div>
        <vs-divider class="mb-2"></vs-divider>
        <div class="vx-row mb-6 ml-4 mr-4">
          <div class="vx-col sm:w-5/5 w-full">
            <datatable :itemRefs="this.itemRefs" :modelItemRefInput="this.tmpItemRefs" @update-itemref="updateitemref"/>
          </div>
        </div>
        <!-- div-parameter -->
        <vs-row  class="mb-3" vs-justify="center">
          <vs-col  vs-type="flex" vs-justify="left" vs-align="left" vs-w="1">
            <vs-button
              @click="handleSubmit()"
              color="primary" 
              icon-pack="feather"
            >Submit </vs-button>
          </vs-col>
        </vs-row>
         
      </vx-card>
    </div>
  </div>
</template>

<script>
import datatable from "./datatable.vue";
import formInputs from './form_inputs.vue'
export default {
  components: {
    datatable,formInputs
  },
  props: {
    id:Number
  },
  data() {
    return this.initialState();
  },
  mounted() {
    console.log("Router: ",this.$router)
  },
  methods: {
    initialState() {
      return {
        selected: {},
        responseData: {},
        users: [],
        type: 0,
        data:{
          npwp:""
        },
        formTaxEntity:{
          id:1,
          inputs:{
            disabled:false,
            validate:'required',
            name:'taxt_entity',
            placeholder:'Taxt Entity',
            type:'multiselect',
            value:{},
            option:[],
            multiple:false,
            track_by:'ID',
            onSelectEvent:(selected)=>{
              console.log(selected)
            },
          }
        },
        itemRefs:[{
          id:1,
          inputs:this.getInputs()
        }],
        tmpItemRefs:{
          id:1,
          inputs:this.getInputs()
        },
      };
    },
    updateitemref(data,status){
      if(status=='add'){
        this.itemRefs.push(data)
      }else if(status=='remove'){
        this.itemRefs = this.itemRefs.filter((v,i) => {
          return i!= data
        });
      }
    },
    getInputs(){
      var inputs = []
      const today = new Date();
      var startDate = new Date(today.getFullYear(), today.getMonth(), today.getDay());
      var endDate = new Date(today.getFullYear(), today.getMonth(), today.getDay()+1);
      inputs.push({
        components:{disabled:false,validate:'required',name:'reference',placeholder:'Reference',type:'input',value:''}
      })
      inputs.push({
        components:{disabled:false,validate:'required',name:'start_date',placeholder:'Start Date',type:'date',value:startDate}
      })
      inputs.push({
        components:{disabled:false,validate:'required',name:'end_date',placeholder:'End Date',type:'date',value:endDate}
      })
      inputs.push({
        components:{disabled:false,validate:'required',name:'prefix',placeholder:'Prefix',type:'input',value:''}
      })
      inputs.push({
        components:{disabled:false,validate:'required',name:'start_number',placeholder:'Start Number',type:'input',value:''}
      })
      inputs.push({
        components:{ disabled:false,validate:'required',name:'end_number',placeholder:'End Number',type:'input',value:''}
      })
      inputs.push({
        components:{disabled:false,validate:'required',name:'total_sn',placeholder:'Total SN',type:'input',value:''}
      })
      inputs.push({
        components:{disabled:false,validate:'required',name:'total_allocation',placeholder:'Total Allocation',type:'input',value:''}
      })
      inputs.push({
        components:{disabled:false,validate:'required',name:'total_remaining',placeholder:'Total Remaining',type:'input',value:''}
      })
      return inputs
    },
    onInput(){
      console.log(this.data.npwp)
    },
    handleSubmit() {
      
    },
    handleEdit() {
      
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    getData() {
      // const params = {
      //   id: this.create.id,
      // }
      // this.$http.get(
      //     `api/v1/approval/matrix`, {params}
      //   )
      //   .then((resp) => {
      //     console.log(this.option.event, 'option')
      //     if (resp.code == 500) {
      //       this.$vs.loading.close();
      //     } else if (resp.code == 200) {
            
      //     } else {
      //       this.$vs.loading.close();
      //     }
      //   });
    },
  },
};
</script>
<template>
    <div class="w-full">
        <vs-input
            v-if="components.type =='input'"
            class="w-full" 
            v-validate="components.validate"
            :name="components.name"
            v-model="components.value"
            :disabled="components.disabled"
            :placeholder="components.placeholder"
            />
        <multiselect
            v-else-if="components.type =='multiselect'"
            class="selectExample"
            v-model="components.value"
            :options="components.option"
            :multiple="components.multiple"
            :allow-empty="false"
            :group-select="false"
            :max-height="160"
            :limit="4"
            @input="components.onSelectEvent"
            :track-by="components.track_by"
            :disabled="components.disabled" 
        >
        
            <template slot="singleLabel" slot-scope="dt">
            <span class="option__desc">
                <span class="option__title">
                {{ dt.option.Reference }}
                </span>
            </span>
            </template>

            <template slot="option" slot-scope="dt">
            <div class="option__desc">
                <span class="option__title">
                {{ dt.option.Reference }}
                </span>
            </div>
            </template>
        </multiselect>
        <datepicker 
            v-else-if="components.type =='date'"
            :inline="false" 
            v-model="components.value" 
            :input-class="'form-control'"
            :placeholder="components.placeholder">
        </datepicker>
        <date-range-picker
            v-else-if="components.type =='daterange'"
            style="min-height: 40px"
            class="w-full"
            ref="picker"
            opens="center"
            :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
            :singleDatePicker="true"
            :timePicker="false"
            :timePicker24Hour="false"
            :showWeekNumbers="false"
            :showDropdowns="false"
            :autoApply="true"
            v-model="components.value"
            :linkedCalendars="true">

            <template v-slot:input="value" style="min-width: 350px">
                {{ datePickerDateFormat(value) }}
            </template>
        </date-range-picker>
    </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import DateRangePicker from "vue2-daterange-picker";
import moment from "moment";
export default {
    components: {
        Datepicker,DateRangePicker,
    },
    props:[
        "components"
    ],
    data(){ 
        return {
            dt : "" 
        }
    },
    mounted() {
        // console.log(this.components)
  },
    methods:{
        datePickerDateFormat(date) {
            let a = null;
            if (date != null) {
                a = moment(date).format("dddd, MMMM Do YYYY");
            }
            return a;
        },
    }
}
</script>
<template>
  <vs-table
  :sst="false"
  :data="itemRefs">
    <template slot="thead">
      <!-- <vs-th></vs-th> -->
      <vs-th
        v-for="(head, index) in this.table.heads"
        :key="index"
        :sort-key="head.field">
          {{ head.title }}
      </vs-th>
      <vs-th>
      </vs-th>
    </template>

    <template>
      <vs-tr :key="index" v-for="(itemRef,index) in this.itemRefs">
        <vs-td :key="fi" v-for="(input,fi) in itemRef.inputs" style="overflow:visible">
          <formInputs :components="input.components"/> 
        </vs-td>
        <vs-td v-if="(index==0)">
          <vx-tooltip text="Add Reference">
              <vs-button
                size="small"
                color="green"
                icon-pack="feather" 
                icon="icon-plus"
                @click="handleAddItemRef()"
                />
              </vx-tooltip>
            </vs-td>
          <vs-td v-else>
            <vx-tooltip text="Delete Reference">
              <vs-button
              size="small"
              color="red"
              icon-pack="feather"
              icon="icon-x"
              @click="handleRemoveItemRef(index)"
            />
          </vx-tooltip>
        </vs-td>
      </vs-tr>
    </template>
  </vs-table>
</template>
  
  
<script>
  import moment from 'moment'
  import formInputs from './form_inputs.vue'
  
  export default {
      components: {
          formInputs
      },
      props: 
    ["itemRefs","modelItemRefInput"],
  mounted() {

  },
  data() {
    return {
      table: this.tableDefaultState(),
    };
  },
  watch: {
    watchedProperties: function () {
      this.getData();
    }
  },
  computed: {
    // watchedProperties() {
    //   return `${this.territories}||${this.docRefType}||${this.search}||${this.supplier}||${this.filterDate.startDate}||${this.filterDate.endDate}||${this.filterPostingDate.startDate}||${this.filterPostingDate.endDate}`;
    // },
    // setPage: {
    //   get() {
    //     return 1;
    //   },
    //   set(val) {
    //     this.handleChangePage(val);
    //   },
    // },
  },
  methods: {
    tableDefaultState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
        heads: [

        { "title": "Reference", "field": "sales_office_code" },
          { "title": "Start Date", "field": "sales_office_desc" },
          { "title": "End Date", "field": "vendor_code" },
          { "title": "Prefix", "field": "vendor_name" },
          { "title": "Start Number", "field": "purchase_org_code" },
          { "title": "End Number", "field": "purchase_org_name" },
          { "title": "Total SN", "field": "debit_note_number" },
          { "title": "Total SN Allocated", "field": "doc_type" },
          { "title": "Total SN Remaining", "field": "doc_ref_type" },

        ],
      };
    },
    handleClose() {
      this.getData();
      this.$emit("close");
    },
    handleAddItemRef(){
      this.$emit("update-itemref", this.tmpItemRefsFunc(),'add');
    },
    tmpItemRefsFunc(){
      const itemRef = JSON.parse(JSON.stringify(this.modelItemRefInput));
      return itemRef
    },
    handleRemoveItemRef(index){
      this.$emit("update-itemref", index,'remove');
    },
    dateFormat(val) {
      return moment(val).format("DD MMM YYYY"); 
    },
    getInputs(){
      var inputs = []
      const today = new Date();
      var startDate = new Date(today.getFullYear(), today.getMonth(), today.getDay());
      var endDate = new Date(today.getFullYear(), today.getMonth(), today.getDay()+1);
      inputs.push({
          components:{
            disabled:false,
            validate:'required',
            name:'reference',
            placeholder:'Reference',
            type:'input',
            value:'',
          }
        })
      inputs.push({
          components:{
            disabled:false,
            validate:'required',
            name:'start_date',
            placeholder:'Start Date',
            type:'date',
            value:startDate,
          }
        })
      inputs.push({
          components:{
            disabled:false,
            validate:'required',
            name:'end_date',
            placeholder:'End Date',
            type:'date',
            value:endDate,
          }
        })
      inputs.push({
          components:{
            disabled:false,
            validate:'required',
            name:'prefix',
            placeholder:'Prefix',
            type:'input',
            value:'',
          }
        })
      inputs.push({
          components:{
            disabled:false,
            validate:'required',
            name:'start_number',
            placeholder:'Start Number',
            type:'input',
            value:'',
          }
        })
      inputs.push({
          components:{ 
            disabled:false,
            validate:'required',
            name:'end_number',
            placeholder:'End Number',
            type:'input',
            value:'',
          }
        })
      inputs.push({
          components:{
            disabled:false,
            validate:'required',
            name:'total_sn',
            placeholder:'Total SN',
            type:'input',
            value:'',
          }
        })
      inputs.push({
          components:{
            disabled:false,
            validate:'required',
            name:'total_allocation',
            placeholder:'Total Allocation',
            type:'input',
            value:'',
          }
        })
      inputs.push({
          components:{
            disabled:false,
            validate:'required',
            name:'total_remaining',
            placeholder:'Total Remaining',
            type:'input',
            value:'',
          }
        })
      return inputs
    },
    formatPrice(val) {
      if (isNaN(val)) {
        val = 0;
      }
      val = (val / 1).toFixed(2).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    format(head, value) {
      if (typeof head["format"] === "function") {
        var f = head["format"];
        return f(value);
      }
      return value;
    },
  }
};
</script>
  
<style>
.core-enter-active {
  transition: all 0.3s ease;
}

.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.core-enter,
.core-leave-to

/* .slide-fade-leave-active below version 2.1.8 */
  {
  transform: translateX(10px);
  opacity: 0;
}

.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}

.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}

.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}
.vs-table--content {
    overflow-y: visible !important;
    overflow-x: visible !important;
}
.vs-con-table .vs-con-tbody {
    overflow-y: visible !important;
    overflow-x: visible !important;
}
.vs-table--tbody-table .tr-values:not(.activeEdit):not(.tr-expandedx):not(.hoverFlat):hover {
    -webkit-transform: none;
    transform: none;
}
</style>
